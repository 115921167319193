window.Client = {

    init: function () {
        // Do something on initialization/

        // 1. Find partner and UTM Source
        const partner = new URLSearchParams(document.location.search).get("partner");
        const utm_source = new URLSearchParams(document.location.search).get("utm_source");
        // 2. Hide header, footer and cookie consent if utm_source is embedding
        // Also set css of body to grayscale
        if (utm_source == "embedding") {
            console.log("Hidding header in embedding")
            const header = document.querySelector('header');
            header.style = "display: none";
            const footer = document.querySelector('footer');
            footer.style = "display: none";
            const cookieConsent = document.querySelector(".heyflow-cookie-consent")
            cookieConsent.style = "display: none";
            const body = document.querySelector("body")
            body.style = "filter: grayscale(1.0)";
        };
        // 3. Get TLD
        // Get the current hostname
        const hostname = window.location.hostname;
        // Split the hostname into parts
        const parts = hostname.split('.');
        // Get the last part, which is the TLD
        const tld = parts[parts.length - 1];
        console.log("The TLD is: " + tld);
        // Check if the TLD is ".com"
        if (tld === "com") {
            // Get the input element by its ID
            var inputElement = document.getElementById('input-6fd904e8');

            // Change the placeholder text
            if (inputElement) {
                inputElement.placeholder = "z.B. 10115";
            };
            
            // Get the input element by its ID
            var inputElement = document.getElementById('id-c564e864');

            // Change the placeholder text
            if (inputElement) {
                inputElement.placeholder = "+496503713937";
            };
            
            // Change the default partner email
            var partnerEmailElement = document.getElementById('partner_email');
            if (partnerEmailElement) {
                partnerEmailElement.href = 'mailto:info@immohelfer.com';
                partnerEmailElement.textContent = "info@immohelfer.com";
            };
        };
    },

    onRender: function (screenId) {
        // Do something when the flow renders
        console.log("Current screen:", screenId);
        
        var btn_verkauf
        var btn_kauf
        var btn_anderer
        
        var btn_sell_now
        var btn_sell_soon
        var btn_sell_later

        // Find button elements
        var btns_r = document.querySelectorAll('input[data-variable="reason"]');
        if (btns_r) {
            btn_verkauf = btns_r[0];
            btn_kauf = btns_r[1];
            btn_anderer = btns_r[2];
        }

        var btns_ts = document.querySelectorAll('input[data-variable="time_sell"]');
        if (btns_ts) {
            btn_sell_now = btns_ts[0];
            btn_sell_soon = btns_ts[1];
            btn_sell_later = btns_ts[2];
        }

        // to prevent multiple times submission
        var lastevent = -1

        // Set var reason to value clicked
        if (btn_verkauf && btn_kauf && btn_anderer) {
            btn_verkauf.addEventListener("click", function () {
                if (lastevent != 0) {
                    console.log("Verkauf btn pressed");
                    // If embedded
                    // parent.window.postMessage("reason-0", "*");
                    // If not embedded
                    dataLayer.push({
                        'reason': 0
                    });
                    dataLayer.push({
                        'event': 'button-verkauf'
                    });
                    lastevent = 0;
                }
            });
            btn_kauf.addEventListener("click",
                function () {
                    if (lastevent != 1) {
                        console.log("Kauf btn pressed");
                        // If embedded
                        //parent.window.postMessage("reason-1", "*");
                        // If not embedded
                        dataLayer.push({
                            'reason': 1
                        });
                        dataLayer.push({
                            'event': 'button-kauf'
                        });
                        lastevent = 1;
                    }
                });
            btn_anderer.addEventListener("click",
                function () {
                    if (lastevent != 2) {
                        console.log("Anderer Grund btn pressed");
                        // If embedded
                        // parent.window.postMessage("reason-2", "*");
                        // If not embedded
                        dataLayer.push({
                            'reason': 2
                        });
                        dataLayer.push({
                            'event': 'button-anderer'
                        });
                        lastevent = 2;
                    }
                });
        }

        // Set var time_sell to value clicked
        if (btn_sell_now && btn_sell_soon && btn_sell_later) {
            btn_sell_now.addEventListener("click", function () {
                if (lastevent != 3) {
                    console.log("Btn now clicked");
                    // If embedded
                    //parent.window.postMessage("reason-0", "*");
                    // If not embedded
                    dataLayer.push({
                        'time_sell': 0
                    });
                    dataLayer.push({
                        'event': 'button-time-now'
                    });
                    lastevent = 3;
                }
            });
            btn_sell_soon.addEventListener("click",
                function () {
                    if (lastevent != 4) {
                        console.log("Btn soon pressed");
                        // If embedded
                        //parent.window.postMessage("reason-1", "*");
                        // If not embedded
                        dataLayer.push({
                            'time_sell': 1
                        });
                        dataLayer.push({
                            'event': 'button-time-soon'
                        });
                        lastevent = 4;
                    }
                });
            btn_sell_later.addEventListener("click",
                function () {
                    if (lastevent != 5) {
                        console.log("Btn later pressed");
                        // If embedded
                        // parent.window.postMessage("reason-2", "*");
                        // If not embedded
                        dataLayer.push({
                            'time_sell': 2
                        });
                        dataLayer.push({
                            'event': 'button-time-later'
                        });
                        lastevent = 5;
                    }
                });
        }

        // Phone number check
        var checkNumber = (target) => {
            console.log("Checking number ...")
            target.value = target.value.replace(".", "")
            var number = encodeURIComponent(target.value);
            if (number == "") return
            fetch("https://www.immohelfer.at/validation/phone/"+number)
            .then(response => {
                if (!response.ok) {
                    throw response;
                }
                return response.json();
            })
            .then(json => {
                if (json.valid != false) {
                    console.log("Mobile number valid :)");
                    if (json.on != false) {
                        console.log("Mobile number on :D");
                        // Add zero-width space if valid, also if not on
                        console.log("Mobile number accepted");
                        telInput.value += ".";
                    } else {
                        console.log("Mobile number not on :(");
                        if (json.whatsapp != false) {
                            console.log("Mobile number has Whatsapp tho :D")
                            // Add zero-width space if valid, also if not on
                            console.log("Mobile number accepted");
                            telInput.value += ".";
                        } else {
                            console.log("Mobile number has no Whatsapp :(");
                            console.log("Mobile number rejected");
                        }
                    }
                } else {
                    console.log("Mobile number not valid :(");
                    console.log("Mobile number rejected");
                }
                return
            })
            .then(()=> {
                if (didSubmit) {
                    submitBtn.click()
                }
            })
            .catch(err => {
                console.log(err)
            })
        };
        // log screen id
        console.log("Current screen:", screenId);

        
        // contact screen with first and last name
        if (screenId === "screen-ffa9beda") {
            
            // Phone number check stuff
            console.log("On contact-info screen");
            var didSubmit = false;
            var submitBtn = document.getElementById("button-789d7259");
            submitBtn.addEventListener('click', ()=> {
                didSubmit = true
            });
            var telId = "id-c564e864";
            var telInput = document.querySelector("#" + telId);
            //telInput.outerHTML = telInput.outerHTML;
            telInput.addEventListener("blur", (event)=> {
                checkNumber(event.target)
            });
            
            // Change partner phone number, email and logo from default
            
            //  1. Get district
            var district = document.querySelector('input[data-variable="district"]').value;
            console.log("District: " + district)

            // 2. Get reason
            var reason;
            for (var i = 0; i < dataLayer.length; i++) {
              if (dataLayer[i].reason !== undefined) {
                // finds the last instance reason was defined
                reason = dataLayer[i].reason;
              }
            }
            console.log("Reason: " + reason);
            
            if (district && (reason || reason==0)) {
                
                const partner = new URLSearchParams(document.location.search).get("partner");
            
                fetch("https://www.immohelfer.at/api/partner-for-district/?district="+ district + "&reason="+reason + "&partner="+partner)
                        .then(response => {
                            if (!response.ok) {
                                console.log("Couldn't fetch partner infos")
                                throw response;
                            }
                            console.log("Fetched partner infos")
                            return response.json();
                        })
                        .then(json => {
                            console.log(json)
                            // 2. Get info
                            var partnerTelValue = json.phone;
                            var partnerEmailValue = json.email
                            var partnerShortcutValue = json.shortcut;
                            
                            // 3.2 Change phone
                            var partnerPhoneElement = document.getElementById('partner_phone');
                            if (partnerPhoneElement) {
                                partnerPhoneElement.href = 'tel:' + partnerTelValue;
                                partnerPhoneElement.textContent = partnerTelValue;
                            }
                            // 3.2 Change email
                            // var partnerEmailElement = document.getElementById('partner_email');
                            // if (partnerEmailElement) {
                            //    partnerEmailElement.href = 'mailto:' + partnerEmailValue;
                            //    partnerEmailElement.textContent = partnerEmailValue;
                            //}
                            
                            // 4. Change logo
                            // const imageUrl = `https://cdn.immohelfer.at/img/partner/${partnerShortcutValue}/avatar.jpg`;
                            // console.log(`Loading ${imageUrl}`);
                            // document.querySelector('img[alt="Avatar"]').src = imageUrl;
                            
                            return
                        })
            } else {
                console.log("Couldn't find district and reason");
            }
            
        };
    }
};